import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
      path: '/stock-images-premium',
      name: 'home',
      component: Home
    },
    // {
    //     path: '/music',
    //     name: 'music-index',
    //     component: () => import(/* webpackChunkName: "music" */ '../views/Music.vue')
    // },
    {
        path: '/music',
        name: 'music-search',
        component: () => import(/* webpackChunkName: "music-search" */ '../views/MusicSearch.vue')
    },
    {
        path: '/music/search',
        name: 'music-search',
        component: () => import(/* webpackChunkName: "music-search" */ '../views/MusicSearch.vue')
    },
    {
        path: '/music/content-:sn',
        name: 'music-content',
        props: true,
        component: () => import(/* webpackChunkName: "music-content" */ '../views/MusicContent.vue')
    },
    // {
    //     path: '/music/category-:category_id',
    //     name: 'music-category',
    //     props: true,
    //     component: () => import(/* webpackChunkName: "music-list" */ '../views/MusicList.vue')
    // },
    {
        path: '/music/list-:id',
        name: 'music-list',
        props: true,
        component: () => import(/* webpackChunkName: "music-list" */ '../views/MusicList.vue')
    },

    // {
    //     path: '/fonts',
    //     name: 'font-index',
    //     component: () => import(/* webpackChunkName: "fonts" */ '../views/Fonts.vue')
    // },
    {
        path: '/fonts',
        name: 'font-index',
        component: () => import(/* webpackChunkName: "fonts" */ '../views/FontAll.vue')
    },

    {
        path: '/fonts/all',
        name: 'font-all',
        component: () => import(/* webpackChunkName: "font-all" */ '../views/FontAll.vue')
    },
    {
        path: '/fonts/search',
        name: 'font-search',
        component: () => import(/* webpackChunkName: "font-search" */ '../views/FontSearch.vue')
    },
    {
        path: '/fonts/content-:sn',
        name: 'font-content',
        props: true,
        component: () => import(/* webpackChunkName: "font-content" */ '../views/FontContent.vue')
    },

    {
        path: '/videos',
        name: 'video-index',
        component: () => import(/* webpackChunkName: "videos" */ '../views/Videos.vue')
    },
    {
        path: '/videos/content-:sn',
        name: 'video-content',
        props: true,
        component: () => import(/* webpackChunkName: "video-content" */ '../views/VideoContent.vue')
    },
    {
        path: '/videos/search',
        name: 'video-search',
        component: () => import(/* webpackChunkName: "video-search" */ '../views/VideoSearch.vue')
    },
    {
        path: '*',
        name: 'not-found',
        props: true,
        component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')
    },
]


let base_url = '/';

const router = new VueRouter({
    mode: 'history',
    base: base_url,
    routes
});



/**
 * 解决报错问题：报错显示是路由重复
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


// 全局导航守卫
// router.beforeEach((to, from, next) => {
//   if (to.path === '/login') return next()
//   const tokenStr = window.sessionStorage.getItem('token')
//   if (!tokenStr) return next('/login')
//   next()
// })


export default router
