import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { setup } from 'axios-cache-adapter';
import device from '@/utils/CheckDevice';
import { VueMasonryPlugin } from 'vue-masonry';
import Notifications from 'vue-notification';

// import './mock/index';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { VBTooltip } from 'bootstrap-vue';
// This order is important



Vue.directive('b-tooltip', VBTooltip)
Vue.config.productionTip = false;

let base_url = '/';
if (process.env.NODE_ENV === 'development') base_url = 'http://beta3.1tu.com/';

const ajax = setup({
    baseURL: base_url,
    cache: {
        maxAge: 20 * 60 * 1000, // 20min
        exclude: {
            query: false
        }
    },

    //withCredentials: true,

    headers: {
        // 'Authorization': 'Basic dHV3ZWltZWk6VFRXV01NQCFAIw==',
        'x-requested-with': 'XMLHttpRequest',
        'X-CSRF-TOKEN': window.csrf_token
    },
});


Vue.prototype.$ajax = ajax;
Vue.prototype.isDesktop = device('desktop');
Vue.prototype.isTablet = device('tablet');
Vue.prototype.isMobile =  device('mobile');

Vue.use(VueMasonryPlugin);
Vue.use(Notifications);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
