<template>
    <div class="box">
        <fieldset class="form pay-form">
            <h2>请扫码进行支付</h2>
            <p>支付金额：{{price}}元</p>
            <ul>
                <li>
                    <div class="check-box" @click="getPayGateway(25)">
                        <label class="ui-checkbox-3" for="pay_view_25">
                            <input type="radio" value="25" id="pay_view_25" name="pay_form_at_view" v-model="pay_type">
                            <svg viewBox="0 0 20 20"><circle class="border" cx="10" cy="10" r="9"></circle><circle class="checked" cx="10" cy="10" r="7"></circle></svg>
                        </label>
                        <img src="/ver.2019/images/wechat.png">
                        <span>微信支付</span>
                    </div>
                </li>

                <li>
                    <div class="check-box" @click="getPayGateway(1)">
                        <label class="ui-checkbox-3" for="pay_view_1">
                            <input type="radio"  value="1" id="pay_view_1" name="pay_form_at_view" v-model="pay_type">
                            <svg viewBox="0 0 20 20"><circle class="border" cx="10" cy="10" r="9"></circle><circle class="checked" cx="10" cy="10" r="7"></circle></svg>
                        </label>
                        <img src="/ver.2019/images/alipay.png">
                        <span>支付宝支付</span>
                    </div>
                </li>
            </ul>

            <div class="qr-image">
                <img v-if="pay_type === 25 && qr_image" :src="qr_image">
                <iframe class="qr-html" v-show="pay_type === 1" ref="frame" :src="gateway_url"></iframe>
            </div>
        </fieldset>
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';

export default {
    name: 'pay',

    data() {
        return {
            pay_type: 0,
            gateway_url: '',
            qr_image: false,
            is_payed: false,
            is_switch: false,
        }
    },

    props: {
        price: Number,
        type: String,
        sn: String,
    },

    methods: {
        getPayGateway(type) {
            let self = this;
            let count = 0;

            if (this.gateway_url !== '') {
                this.is_switch = true;

                setTimeout(() => {
                    this.is_switch = false;
                }, 3000);
            }

            this.pay_type = type;
            this.gateway_url = `/pay-gateway.do?pay_for=5&pay_type=${this.pay_type}&${this.type}_sn=${this.sn}&user_id=${this.$store.state.user_id}`;


            if (this.pay_type === 25) {
                this.$ajax.get(this.gateway_url).then(res => {
                    this.qr_image = res.data.url;
                });
            }

            if (window.pay_interval) window.clearInterval(window.pay_interval);

            window.pay_interval = window.setInterval(() => {
                count ++;
                self.$ajax.get('/pay-gateway/queryOrder.do?pay_at=5&count=' + count).then(res => {
                    let result = JSON.parse(res.data.replace('payCallback(', '').replace(');', ''));
                    // console.log(count, result);

                    if(result.qResult == 1) {
                        window.clearInterval(window.pay_interval);
                        // Removed all cache
                        self.$ajax.cache.store = {};

                        if (result.error == 1) {
                            // yt5.showMessage(result.content);
                            return false;
                        } else {
                            self.$emit('payed');
                            // switch (result.pay_at) {
                            //     case 1:
                            //         // download?
                            //         console.log(self);
                            //         self.$emit('payed');
                            //         break;
                            //     case 2:
                            //         // cart?
                            //         window.location.href = '/user/';
                            //         break;
                            //     case 4:
                            //         window.location.href = '/orders/subscription.html';
                            //         break;
                            //     case 5:
                            //         window.location.href = '/user/';
                            //         break;
                            // }
                        }
                    }


                });

                if(count > 180) window.clearInterval(window.pay_interval);
            }, 3000);


        },




    },

    mounted() {
        EventBus.$on('close-modal', () => {
            if (window.pay_interval) window.clearInterval(window.pay_interval);
        });
    },

}

</script>

<style lang="scss" scoped>
    @import "@/assets/sass/_variable.scss";

    ul {
        display: flex;
        padding: 0 0 2rem;

        li {
            width: 50%;

            .check-box {
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                overflow: hidden;
            }

            img {
                width: 1.5rem;
                margin: 0 .5rem;
                display: block;
            }

            .ui-checkbox-3 {
                display: block;
                height: 1.1rem;
                width: 1.1rem;

                input {
                    padding: 100rem;
                }

                svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    .qr-image {
        text-align: center;

        .qr-html,
        img {
            margin: 0 auto 1rem auto;
            width: 180px;
            display: block;
            height: 180px;
        }
    }
</style>
