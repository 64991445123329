<template>
    <div v-html="htmlstr">

    </div>
</template>

<script>



export default {
    name: 'new-footer',

    data() {
        return {
            htmlstr: '',
        }
    },

    mounted() {
        this.$ajax.get('/footer-copyrights.html').then(res => {
            this.htmlstr = res.data;
        });
    },
}
</script>
