<template>
    <div class="music-player">
        <div class="box" v-if="item">

          <div class="avatar">
            <img :src="item.image">
            <span class="play" v-if="!is_playing" @click="play(false)"><svg><use xlink:href="#play--sprite"></use></svg></span>
            <span class="play pause" v-if="is_playing" @click="pause"><svg><use xlink:href="#pause--sprite"></use></svg></span>
          </div>
            <div class="info">
                <h2 v-b-tooltip.hover :title="item.title">{{item.title}}</h2>
                <p><span v-b-tooltip.hover title="点击拷贝ID" @click="copy(item.music_sn)">{{item.music_sn}}</span></p>
                <h3 v-if="item.author">{{item.author}}</h3>
            </div>


            <div class="wave" ref="el"></div>

            <div class="meta">
                <time>时间：{{time}}</time>
                <p v-if="item.bpm">节拍：{{item.bpm}}</p>
                <span v-if="item.favor" class="actived" v-b-tooltip.hover :title="item.favor"
                      @click="favorite"><svg><use
                  xlink:href="#favorite--sprite"></use></svg></span>
                <span v-else v-b-tooltip.hover title="加入收藏" @click="favorite"><svg><use xlink:href="#favorite--sprite"></use></svg></span>
                <span class="button" @click="download">下载小样</span>
                <!-- <span v-b-tooltip.hover title="下载小样" @click="download"><svg><use xlink:href="#download2--sprite"></use></svg></span> -->
            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/svg/play.svg?sprite";
import "@/assets/svg/pause.svg?sprite";
import "@/assets/svg/download2.svg?sprite";
import "@/assets/svg/favorite.svg?sprite";

import { EventBus } from '@/events/bus.js';
import WaveSurfer from 'wavesurfer.js';

export default {
    name: 'music-player',

    data() {
        return {
            item: {
                id: 0,
                src: '',
                link: '',
                title: '',
                name: '',
            },
            waveSurfer: {},
            is_playing: false,
            time: '00:00',
        }
    },



    methods: {
        async copy(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.$notify(
                    {
                        type: 'success',
                        text: `已拷贝ID(${text})到您的剪贴板`
                    }
                );
            } catch($e) {
                this.$notify(
                    {
                        type: 'error',
                        text: `拷贝失败`
                    }
                );
            }
        },

        reset(time) {
            let self = this;
            //let options = this.options;
            //let wsOptions = Object.assign({ container: this.$el }, options);
            self.waveSurfer.load(self.item.src);

            self.waveSurfer.on('ready', function (e) {
                let audioCurrentTime = self.waveSurfer.getDuration()
                let minutes = '0' + Math.floor(audioCurrentTime / 60);
                let seconds = '0' +  Math.floor(audioCurrentTime - minutes * 60);
                self.time = minutes.substr(-2) + ':' + seconds.substr(-2);

                self.play(time);
            });
        },

        favorite() {
            if (!this.$store.state.is_logged_in) {
                EventBus.$emit('show-login-modal');
                return;
            }
            const fdata = {
                sn: this.item.music_sn,
                media_type: 'music',
                cancel: 0,
            }

            if (this.favor) {
                fdata.cancel = 1;
            }

            this.$ajax('/post-collection.html', {
                method: 'post',
                data: fdata,
            }).then((res) => {
                let err = 'error';

                if(res.data.error === 0) {
                    err = 'success';

                    if (this.item.favor) {
                        this.item.favor = false;
                    } else {
                        this.item.favor = '已收藏';
                    }
                }

                this.$notify({
                    'type':err,
                    'text':`${res.data.content}`
                });
            });
        //   this.$ajax('/post-collection.html', {
        //     method: 'post',
        //     data: {
        //       sn: this.item.music_sn,
        //       media_type:'music'
        //     },

        //   }).then((res) => {
        //     let err = 'error';

        //     if(res.data.error === 0){
        //       this.item.favor = '已收藏';
        //       err = 'success';
        //     }
        //     this.$notify({
        //       'type': err,
        //       'text':`${res.data.content}`
        //     });
        //   });
        },


      download() {
            let a = document.createElement('a');
            a.href = this.item.download_link;
            a.download = this.item.music_sn;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },

        play(time) {

            this.is_playing = true;
            if (typeof time === 'number') {
                this.waveSurfer.play(time);
            } else {
                this.waveSurfer.play();
            }

            EventBus.$emit('play-all-music', {
                id: this.item.id,
                time: this.waveSurfer.getCurrentTime()
            });

        },
        pause() {
            this.is_playing = false;
            this.waveSurfer.pause();

            EventBus.$emit('pause-all-music', {
                type: 'pause',
                id: this.item.id,
            });
        },
    },
    mounted() {
        let self = this;
        let rtime;
        let timeout = false;
        let delta = 500;

        self.waveSurfer = new WaveSurfer.create({
            container: self.$refs.el,
            waveColor: '#ababab',
            progressColor: '#FF5B00',
            cursorColor: '#FF5B00',
            barWidth: 2,
            barRadius: 0,
            cursorWidth: 0,
            height: 40,
            barGap: 3,
            responsive: true,
        });

        // self.reset(0);

        self.waveSurfer.on('audioprocess', function () {
            // console.log(self.waveSurfer.getCurrentTime())
            //console.log(self.waveSurfer.getCurrentTime())
            EventBus.$emit('sync-all-music', {
                type: 'sync',
                id: self.item.id,
                time: self.waveSurfer.getCurrentTime()
            });

        });




        // audioprocess
        // EventBus.$off('post-category');
        EventBus.$on('show-player-music', obj => {
            if (obj.id === self.item.id) {
                self.play(obj.time);
            } else {
                self.item = obj.item;
                self.reset(obj.time);
            }

        });

        EventBus.$on('pause-player-music', obj => {
            self.is_playing = false;
            self.waveSurfer.pause();
        });
    },
}
</script>
<style lang="scss">
    @import "@/assets/sass/_variable.scss";

    .music-player {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        padding: 1rem;
        background: white;
        border-top: 1px solid $new_button_color;
        .avatar{
          height: 6rem;
          width: 6rem;
          position: relative;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .box {
            display: flex;
            align-items: center;
            position: relative;
        }


        .play {
            height: 6rem;
            width: 6rem;
            position: absolute;
            cursor: pointer;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .3rem;

            svg {
              height: 50%;
              width: 50%;
              position: relative;
              fill: white;
            }

            &.pause,
            &:hover {
                background: $orange;
                mix-blend-mode: lighten;
            }
        }
        .button {
            border: 1px solid $new_button_color;
            height: 2rem;
            line-height: 2rem;
            padding: 0 .7rem;
            font-size: .9rem;
            margin-left: .5rem;

            &:hover {
                color: $orange;
                border-color: $orange;
            }
        }
        .info {
            padding: 0 1rem;
            width: 12rem;

            h2 {
                overflow:hidden;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-line-clamp:2;
                -webkit-box-orient:vertical;
                margin: 0 0 .5rem;
            }

            p {
                margin: 0;
            }

            h3 {
                font-weight: normal;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
        }

        .wave {
            flex: 1;

            wave {
                overflow: hidden !important;
            }

        }

        .meta {
            display: flex;
            align-items: center;
            p {
                margin: 0 1rem;
            }

            time {
                padding: 0 0.5rem;

            }

            span {
                padding: 0 0.5rem;
                cursor: pointer;

                svg {
                    height: 1.8rem;
                    width: 1.8rem;
                }

                &.actived svg,
                &:hover svg {
                    fill: $orange;
                }

                & + span svg {
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }
        }
    }



</style>
