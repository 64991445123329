<template>
    <div id="app" class="wrap">
        <Header />
        <router-view />
        <Footer />
        <Modal ref="buy">
            <div class="box">
                <fieldset class="form">
                    <button class="is-close close-modal" title="Close"></button>
                    <h2 class="title">请确认</h2>
                    <div class="message" v-if="subscription">请确认使用 {{subscription.type}}套餐 下载{{ref_name}}？</div>
                    <div class="message" v-else-if="font_content && font_content.open_source">下载开源字体 {{font_content.title}}</div>
                    <div class="message" v-else>您还没有购买{{ref_name}}套餐，请前往购买。</div>
                    <div class="form-item form-submit">
                        <button type="button" class="button button-green" @click="buy">确 认</button>
                        <button type="button" style="margin-left:1rem;" class="is-close button button-gray" @click="$refs.buy.close()">取 消</button>
                    </div>
                </fieldset>
            </div>
        </Modal>

        <Modal ref="download" >
            <div class="box">
                <fieldset class="form">
                    <button class="is-close close-modal" title="Close"></button>
                    <h2 class="title">{{download_title}}</h2>
                    <div class="message" v-html="download_msg"></div>
                    <div class="form-item form-submit">
                        <button v-if="download_buy_btn" type="button" class="button button-green"  @click="buy"
                                style="margin-right:1rem;">确 认
                        </button>
                        <button type="button" class="is-close button button-gray" @click="$refs.download.close()">关闭窗口
                        </button>
                    </div>
                </fieldset>
            </div>
        </Modal>

        <Modal ref="pay" v-if="font_content">
            <Pay :price="Number(font_content.price)" :type="'font'" :sn="font_content.font_sn" @payed="payed" />
        </Modal>

      <Modal ref="downloadWarning" v-if="music_content">
        <div class="box">
          <fieldset class="form">
            <button class="is-close close-modal" title="Close"></button>
            <h2 class="title">下载音频小样</h2>
            <div class="message">您将下载带水印低音质版本的曲目音频，该版本仅供学习，研究或欣赏使用，继续下载吗？</div>
            <div class="form-item form-submit">
              <button type="button" class="button button-green" @click="download()">下 载</button>
            </div>
          </fieldset>
        </div>
      </Modal>

      <Modal ref="contact">
        <div class="box">
          <fieldset class="form">
            <button class="is-close close-modal" title="Close"></button>
            <h2 class="title">请联系客服</h2>
            <div class="message">详情请咨询客服，客服电话：15701587002</div>
            <div>
              <button type="button" class="button button-green"  @click="$refs.contact.close()" >确 认</button>
              <button type="button" style="margin-left:1rem;" class="button button-gray" @click="$refs.contact.close()">取 消</button>
            </div>
          </fieldset>
        </div>
      </Modal>
        <notifications position="bottom center" />
        <Loading v-if="$store.state.show_loading" />
    </div>
</template>

<script>
import "@/assets/svg/favorite.svg?sprite";
import "@/assets/svg/images.svg?sprite";
import "@/assets/svg/download.svg?sprite";
import "@/assets/svg/cart.svg?sprite";
import "@/assets/svg/add_cart.svg?sprite";
import "@/assets/svg/arrow_up.svg?sprite";
import "@/assets/svg/copy.svg?sprite";

import { EventBus } from '@/events/bus.js';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Player from "@/components/MusicPlayer.vue";
import Modal from "@/components/Modal.vue";
import Loading from "@/components/Loading.vue";
import Pay from "@/components/Pay.vue";

export default {
    name: 'app',
    data() {
        return {
            download_title: '',
            download_msg: '',
            download_link: '',
            download_filename: '',
            subscription: false,
            download_buy_btn: false,
            font_content: false,
            music_content: false,
            music_subscription: false,
            font_subscription: false,
            ref: 'fonts',//moban,music
            ref_name: '',//moban,music
        }
    },
    components: {
        Footer,
        Header,
        Player,
        Modal,
        Pay,
        Loading,
    },

    watch: {
        '$route.params': {
            handler(params) {
                this.updateClass(params);
            },
            immediate: true,
        },
        ref:{
            handler(newVal){
                if(newVal === 'music'){
                    this.ref_name = '音乐';
                    // this.subscription = this.music_subscription;
                }else if(newVal === 'fonts'){
                    this.ref_name = '字体';
                    // this.subscription = this.font_subscription;
                }
                // console.log('ref changed',newVal ,this.ref_name,this.subscription)
            },
            deep: true
        }

    },

    methods: {

        showBuyModel() {
            if (!this.$store.state.is_logged_in) {
                EventBus.$emit('show-login-modal');
            } else {
                if(this.ref ==='fonts' && !this.subscription &&  !this.font_content.open_source){
                  this.$refs.contact.open();
                }else{
                  this.$refs.buy.open();
                }
            }
        },

        buy() {
            let open_source = this.font_content.open_source ||0;
            if (!this.$store.state.is_logged_in) {
                EventBus.$emit('show-login-modal');
            } else if (!this.subscription && !open_source) {
                this.buyPackage();
            } else {
                this.$store.state.show_loading = true;
                const buyUrl = "/ajax/" + this.ref + "/imm-download";
                this.$ajax(buyUrl, {
                    method: 'post',
                    data: {
                        user_id: this.$store.state.user_id,
                        font_sn: this.font_content.font_sn || 0,
                        music_sn: this.music_content.music_sn || 0,
                        sub_id: this.subscription.id || 0 ,
                    },
                }).then((res) => {
                    console.log(res);

                    this.download_buy_btn = false;
                    switch (res.data.error) {
                        case 0:
                            this.download_title = '购买成功';
                            this.download_link = res.data.content;
                            this.download_filename = this.font_content.title;
                            this.download_msg = `
                                下载将自动开始，如果没有自动开始下载<br>
                                请<a download="${this.download_filename}" href="${this.download_link}">点击这里</a>下载字体。
                                或者到<a href="/orders/fonts.html">会员中心</a>下载字体。
                            `;

                            // this.download();
                            break;

                        case 1:
                        case 500:
                            this.download_title = '购买失败，' + res.data.msg;
                            this.download_link = '';
                            this.download_filename = '';
                            this.download_msg = `
                                请联系客服咨询，客服电话：15701587002
                            `;

                            break;

                        case 2:
                            this.download_title = '购买失败，' + res.data.msg;
                            this.download_link = '';
                            this.download_filename = '';
                            this.download_msg = '';

                            break;

                        case 3:
                            this.download_title = '您已购买了该' + this.ref_name;
                            this.download_link = res.data.content;
                            this.download_filename = res.data.filename;
                            this.download_msg = `
                                您已购买了该${this.ref_name}，请<a download="${this.download_filename}" href="${this.download_link}">点击这里</a>下载${this.ref_name}。
                                或者到<a href="/orders/${this.ref}.html">会员中心</a>下载${this.ref_name}。
                            `;

                            break;

                        case 4:
                            this.download_title = '余额不足';
                            this.download_link = '';
                            this.download_filename = '';
                            this.download_msg = '';

                            break;


                        case 5:
                            this.download_title = '支付失败，' + res.data.msg;
                            this.download_link = '';
                            this.download_filename = '';
                            this.download_msg = '';

                            break;
                        case 6:
                            // 套餐购买，余量不足
                            // 一般情况下返回的套餐都是有余量的，以下是当前页面停留过久套餐实际已用完的处理。
                            // 先使用套餐下载，套餐余量不足时，
                            // 如果有别的套餐可用，继续使用其他套餐，
                            // 直到没有任何套餐可用，提示购买套餐
                            this.download_title = '支付失败，' + res.data.msg;
                            if(Object.keys(res.data.other_sub).length > 0) {
                                this.subscription = res.data.other_sub;
                                this.download_msg = '使用 ' + res.data.other_sub.type + ' 套餐购买';

                            }else{
                                this.subscription = false;
                                // this.show_download_price_btn = true;
                                this.download_msg = '请前往购买新的' + this.ref_name + '套餐。';
                            }
                            this.download_link = '';
                            this.download_filename = '';
                            this.download_buy_btn = true;
                            break;

                        case 10:
                            this.download_title = '订单处理中';
                            this.download_link = '';
                            this.download_filename = '';
                            this.download_msg = `
                                    订单处理中，请稍候到<a href="/orders/${this.ref}.html">会员中心</a>下载。
                                `;

                            break;

                    }

                    this.$refs.buy.close();

                    if (res.data.error === 4) {
                        this.$refs.pay.open();
                    } else {
                        this.$refs.download.open();
                        if (this.download_link) this.download();
                    }


                    this.$store.state.show_loading = false;
                });
            }
        },

        download() {
            const link = document.createElement('a');
            link.href = this.download_link;
            if(this.ref ==='fonts'){
                link.download = '1tu.com ' + this.font_content.title + '.ttf';

            }else{
                this.$refs.downloadWarning.close();
            }
            document.body.appendChild(link);
            // console.log(link);
            link.click();
            document.body.removeChild(link);
        },

        payed() {
            // console.log('payed');
            this.$refs.pay.close();
            this.buy();
        },

        buyPackage() {
            let a = document.createElement('a');
            a.href = '/buy-package.html?ref=' + this.ref ;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },

        updateClass(params) {
            let body_class = '';
            if (typeof this.$route.name === 'string') body_class = 'page-' + this.$route.name;
            document.body.className = body_class;
            window.scrollTo(0, 0);

            // music-home-page
        },
        showDownloadWarning() {

            this.download_link =this.music_content.download_link;
            this.$refs.downloadWarning.open();
        },


    },

    mounted() {
        this.$ajax.get('/ajax/fonts/subscriptions').then((res) => {
            this.font_subscription = res.data.data;
        });

        EventBus.$on('buy-font', (obj) => {
            this.ref = 'fonts';
            this.font_content = obj.font_content;
            this.subscription = this.font_subscription;

            setTimeout(() => {
                this.showBuyModel();
            }, 1);
        });

        EventBus.$on('buy-music', (obj) => {
            this.ref = 'music';
            this.music_content = obj.music_content;
            this.subscription = obj.subscription;
            // console.log('music sub',this.subscription)
            setTimeout(() => {
                this.showBuyModel();
            }, 1);
        });

        EventBus.$on('show-login-modal', () => {
            window.location.href = '/user/login';
        });

        EventBus.$on('show-register-modal', () => {
            window.location.href = '/user/login';
        });

        EventBus.$on('show-demo-warning-modal', (obj) => {
            // console.log('on show warning',obj.music)
            this.ref = 'music';
            this.music_content = obj.music;

            setTimeout(() => {
                this.showDownloadWarning();
            }, 1);
        });
        // EventBus.$on('hide-login-modal', () => {
        //     this.$refs.login.close();
        // });


        // EventBus.$on('hide-register-modal', () => {
        //     this.$refs.register.close();
        // });


        if (typeof window.global_uid !== 'undefined' && window.global_uid !== 0) {
            this.$store.state.is_logged_in = true;
            this.$store.state.user_id = window.global_uid;
        }

        if (typeof window.SALE_MUSICS !== 'undefined' ) {
            this.$store.state.sale_music = window.SALE_MUSICS;
        }

        if (typeof window.SALE_FONT !== 'undefined' ) {
            this.$store.state.sale_font = window.SALE_FONT;
        }
        if (typeof window.SALE_VIDEOS !== 'undefined' ) {
            this.$store.state.sale_video = window.SALE_VIDEOS;
        }
        if (typeof window.music_banner_text !== 'undefined' ) {
            this.$store.state.music_banner_text = window.music_banner_text;
        }
        if (typeof window.font_banner_text !== 'undefined' ) {
            this.$store.state.font_banner_text = window.font_banner_text;
        }

        if (typeof window.package_min_price !== 'undefined' ) {
            this.$store.state.package_min_price = window.package_min_price;
        }


    },
}
</script>
<style lang="scss">
    @import "@/assets/sass/_variable.scss";

    .wrap {
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 100%;
        min-height: 100vh;

        label,
        ul {
            margin: 0;
        }

        h1,h2,h3,h4,h5 {
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
        }

        .home-heading h2 {
            font-size: 2.5rem;
        }
    }

    .page-font-index .wrap,
    .page-font-all .wrap,
    .page-font-search .wrap {
        grid-template-rows: auto 1fr auto;

        .search-info .text {
            display: none;
        }
    }

    .inner {
        padding: 0 1.3rem;
    }

    .search-content-inner {
        width: 92vw;
        // max-width: 1600px;
        max-width: unset;
        margin: auto;
        padding: 0 1.3rem;
        box-sizing: border-box;
    }
    
    // .page-stock_vectors_result .search-content-inner,
    // .page-stock_photos_result .search-content-inner,
    // .page-stock_images_result .search-content-inner {
    //     max-width: unset;
    // }

    .music-page-header {
        h1 {
            text-align: center;
            color: $new_font_dark;
            padding: 2rem 0 2rem 0;
            font-size: 1.5rem;
            font-weight: 300;
        }
    }

    .music-tags {
        display: flex;
        margin: 0 0 .5rem 0;
        justify-content: center;

        li {
            width: auto;
            text-align: center;
            box-sizing: border-box;
            padding: 0 1.5rem;

            span {
                cursor: pointer;
                display: block;
                padding:  0 0 1rem;
                border-bottom: .1rem solid transparent;

                &:hover {
                    color: $orange;
                }
            }

            &.actived {
                color: $orange;
                font-weight: 600;
            }
        }
    }

    .tag-list {
        padding: 1rem .5rem 2rem;
        text-align: left;

        li {
            padding: .5rem;
            display: inline-block;

            a {
                display: inline-block;
                padding: 0 2rem;
                height: 2.6rem;
                line-height: 2.6rem;
                background: white;
                border-radius: 1.5rem;
                color: $new_font_dark;
                background: $new_bg_color;

                &:hover {
                    background: $orange;
                    border-color: $orange;
                    color: white;
                }
            }
        }
    }

    .side-enter-active, .side-leave-active {
        transition: transform .3s;
    }
    .side-enter, .side-leave-to {
        transform: translate(0, 100%);
    }

    .is-loading {

        &::before {
            animation: ld-spin .5s linear infinite;
            width: 32px;
            height: 32px;
            border: 5px solid rgba(0,0,0,0.25);
            border-left-color: transparent;
            display: block;
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            visibility: visible;
            border-radius: 50%;
        }


    }

    @keyframes ld-spin-fast {
        0%{
            animation-timing-function:cubic-bezier(0.5856,0.0703,0.4143,0.9297);
            transform:rotate(0);
        }
        100%{
            transform:rotate(1800deg);
        }
    }

    @keyframes ld-spin{0%{animation-timing-function:cubic-bezier(0.5856,0.0703,0.4143,0.9297);transform:rotate(0)}100%{transform:rotate(360deg)}}

    .vue-notification-group {
        z-index: 999999;
        pointer-events: none;
        top: 0;
        height: 100%;
        display: flex !important;
        align-items: center;
        text-align: center;

        & > span {
            width: auto;
            display: block;
            margin: 0 auto;
        }
    }

    .vue-notification-wrapper .vue-notification {
        background-color: #f9edbe !important;
        border: 1px solid #f0c36d !important;
        border-radius: $radius;
        box-shadow: 0 2px 4px rgba(#000, .2);
        color: #222;
        padding: 6px 1rem;
        font-weight: bold;
        font-size: 1.25rem;
        pointer-events: none;
        width: auto;
    }

    .banner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28rem;
        width: 100%;
        background: black;
        position: relative;

        .image {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        .cover-video {
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            z-index: -100;
            right: 0;
            bottom: 0;
            overflow: hidden;
            opacity: .6;
        }


        .swiper-container {
            position: absolute !important;
            left: 0 !important;
            top: 0 !important;
            width: 100% !important;
            height: 100% !important;

            .swiper-slide {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;

                &::before {
                    height: 100%;
                    width: 100%;
                    content: '';
                    left: 0;
                    top: 0;
                    background: #000;
                    position: absolute;
                    opacity: 0.3;

                    @at-root {
                        body.page-stock_images_premium &,
                        body.page-index_index & {
                            display: none !important;
                        }
                    }
                }

                a {
                    display: block;
                    z-index: 9;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    position: absolute;

                    span {
                        position: absolute;
                        right: 1rem;
                        bottom: .5rem;
                        font-size: .8rem;
                        color: white;
                        display: flex;
                        align-items: center;
                        padding: 0 .7rem;
                        height: 1.8rem;
                        background: rgba(0,0,0,0);
                        border-radius: .9rem;
                        cursor: pointer;
                        opacity: .3;
                        transition: all .3s;

                        &:hover {
                            opacity: 1;
                            background: rgba(0,0,0,.65);

                            svg {
                                left: .2rem;
                            }
                        }

                        svg {
                            height: .6rem;
                            width: .6rem;
                            margin-right: .5rem;
                            position: relative;
                            fill: white;
                            transition: all .3s;
                            left: 0;
                        }
                    }
                }
            }


            .swiper-pagination-bullet {
                background: white;
                opacity: .3;
            }

            .swiper-pagination-bullet-active {
                opacity: 1;
                background: white;
            }


            .arrow {
                position: absolute;
                height: 3rem;
                width: 3rem;
                z-index: 99;
                top: 50%;
                margin: -2.5rem 0 0;
                cursor: pointer;
                opacity: .85;
                border-radius: 50%;
                background: rgba(0,0,0,.2);


                &:hover {
                    opacity: 1;
                    // background: rgba(#fff, .05);
                }


                &.prev {
                    left:  .75rem;
                }

                &.next {
                    right: .75rem;
                }

                svg {
                    height: 50%;
                    width: 50%;
                    position: absolute;
                    left: 25%;
                    top: 25%;
                    fill: white;
                    transition: .3s opacity ease;
                }
            }
        }

    }

    .font-list {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 50%;
            box-sizing: border-box;
            padding: .5rem;
        }

        a {
            border-radius: $radius;
            overflow: hidden;
            border: 1px solid $new_button_color;
            display: block;

            &:hover{
                background: $new_bg_color;
            }
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            border-bottom: 1px solid $new_button_color;
        }

        h3 {
            padding: 1rem;
            color: $new_font_dark;
        }
    }

    .font-list-2 {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 33.33%;
            box-sizing: border-box;
            padding: .5rem;
        }

        a {
            overflow: hidden;
            display: block;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            border-radius: $radius;
            border: 1px solid $new_button_color;
        }

        h3 {
            padding: 1rem 0;
            color: $new_font_dark;
        }
    }

    .font-list-3 {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 25%;
            box-sizing: border-box;
            padding: .5rem;
        }

        a {
            overflow: hidden;
            display: block;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            border-radius: $radius;
        }

        h3 {
            padding: 1rem 0;
            color: $new_font_dark;
        }
    }

    .my-notifications {
        // styling
        margin: 0 5px 5px;
        padding: 10px;
        font-size: 12px;
        color: #ffffff;

        // default (blue)
        background: #44A4FC;
        border-left: 5px solid #187FE7;

        // types (green, amber, red)
        &.success {
            background: #68CD86;
            border-left-color: #42A85F;
        }

        &.warn {
            background: #ffb648;
            border-left-color: #f48a06;
        }

        &.error {
            background: #E54D42;
            border-left-color: #B82E24;
        }
    }

    body.page-help_view .page-banner .bottom-text {
        bottom: auto;
    }

    .global-menus {
        padding: .5rem 1.5rem;

        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            li {
                padding-right: 2rem;

                a {
                    color: $new_font_dark;
                    font-size: 1.3rem;
                    cursor: pointer;

                    &.active,
                    &.router-link-exact-active,
                    &.router-link-active {
                        position: relative;
                        font-weight: bold;
                        border-bottom: none;

                        &::before {
                            height: 3px;
                            width: 100%;
                            position: absolute;
                            content: '';
                            left: 0;
                            width: 100%;
                            bottom: -1.6rem;
                            background-color: $orange;
                        }
                    }

                    &:hover {
                        color: $orange;
                        border-bottom: solid 1px;
                    }
                }
            }
        }
    }

    // body.page-music-search,
    // body.page-music-content {
    //     .header-wrap .status-bar li:first-child,
    //     .search-wrap .search-form-input .select-type,
    //     .global-header.fixed .header-wrap .navi>span:nth-child(2), 
    //     .is-inner-page .header-wrap .navi>span:nth-child(2) {
    //         display: none;
    //     }
    // }
</style>
