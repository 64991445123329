import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    music: false,
    show_player: false,
    show_loading: false,
    is_logged_in: false,
    user_id: 0,
    sale_music: 0,
    sale_font: 0,
    sale_video: 0,
    font_banner_text: '',
    music_banner_text: '',
    package_min_price: 1,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
