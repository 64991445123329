<template>
    <div class="modal" ref="modal">
        <div class="wrap-box">
            <div class="inner-box">
                <slot></slot>
                <button class="close-modal" title="Close" @click="close"></button>
            </div>
        </div>
        <div class="bg" @click="close"></div>
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';

export default {
    name: 'modal',
    data() {
        return {
            is_show: false,
        }
    },
    methods: {
        open() {
            this.$refs.modal.classList.add('open');
            EventBus.$emit('open-modal');

            setTimeout(() => {
                this.$refs.modal.classList.add('show');
            }, 10);
        },
        
        close() {
            this.$refs.modal.classList.remove('show');
            EventBus.$emit('close-modal');

            setTimeout(() => {
                this.$refs.modal.classList.remove('open');
            }, 310);
        },

    },
}

</script>

<style lang="scss" scoped>
    @import "@/assets/sass/_variable.scss";

    .modal {
        display: none;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        z-index: 11050;

        .box {
            position: relative;
            overflow: hidden;
        }

        .message {
            padding-bottom: .5rem;
            line-height: 1.7;
        }

        button:focus {
            outline: none;
        }

        &.open {
            display: block;
        }

        &.show {
            opacity: 1;
        }
    }
</style>
